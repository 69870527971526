import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import othersImg from '../assets/images/others.svg';
import burgerMenu from '../assets/images/burgerMenu.png';
import xButton from '../assets/images/X button.png';
import subVideo from '../assets/videos/activeSubVideo.mp4'
import mysticalZyngaroBottle from "../assets/images/mysticalZingaroImage.png"
import redSkiesBottle from "../assets/images/redSkiesImage.png"

class SubscriptionsPage extends React.Component {
  constructor(props) {
    super(props);

    // our component's state
    this.state = {
      username: '',
      menuOpen: false,
      subscriptions: []
    }
  }

  menuButtonClicked = () => {
    this.setState({menuOpen: !this.state.menuOpen});
  }

  logOutClicked = async () => {
    try {
      await axios.get('/v2/session_logout/', { withCredentials: true })
      window.location.href = '/login';
    } catch (error) {
      console.log(error);
    }
  }

//  TESTING ASYNC FOR MOBILE USE 

  // async componentDidMount() {
  //   try {
  //     // Simulated subscriptions data for testing
  //     const sampleSubscriptions = [
  //       {
  //         fragrance_type: 'red',
  //         is_active: true,
  //         expiration_date: '2023-12-31', // Replace with the desired expiration date
  //       },
  //       {
  //         fragrance_type: 'mystical',
  //         is_active: true,
  //         expiration_date: '2023-12-31',
  //       },
  //       {
  //         fragrance_type: 'mystical',
  //         is_active: false,
  //         expiration_date: '2022-12-22',
  //       },
  //     ];

  //     // Simulated profile data for testing
  //     const sampleProfile = {
  //       username: 'testuser',
  //     };

  //     // Create pretty dates for sample subscriptions
  //     var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  //     sampleSubscriptions.forEach((sub) => {
  //       sub.pretty_date = new Date(sub.expiration_date).toLocaleDateString('en-US', options);
  //     });

  //     this.setState({ subscriptions: sampleSubscriptions, username: sampleProfile.username });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  async componentDidMount() {
    try {
      // get subscriptions
      const response = await axios.get('/v1/subscriptions/', {
        withCredentials: true
      });
      console.log('Subscriptions: ' + response.data.subscriptions);

      // create pretty dates
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      response.data.subscriptions.forEach((sub) => {sub.pretty_date = new Date(sub.expiration_date).toLocaleDateString("en-US", options)});
      
      this.setState({subscriptions: response.data.subscriptions});

      // get profile to get username/email
      const profile = await axios.get('/v2/session_profile/', {
        withCredentials: true })
      ;
      this.setState({username: profile.data.username});
    } 
    catch (error) {
      console.error(error);
    }
  }

  onTheScienceClicked = () => {
    window.location.href = 'https://others.co/pages/science';
  }

  onFAQClicked = () => {
    window.location.href = '/faq.html';
  }

  onTNCClicked = () => {
    window.location.href = '/terms-and-conditions.html';
  }

  onPrivacyPolicyClicked = () => {
    window.location.href = '/privacy-policy.html';
  }

  onSupportClicked = () => {
    window.location.href = '/support.html';
  }

  render() {
    const activeSubscriptions = this.state.subscriptions.filter(sub => sub.is_active);
    const expiredSubscriptions = this.state.subscriptions.filter(sub => !sub.is_active);

    // Create a set of unique subscription types
    const uniqueSubscriptionTypes = new Set();
    activeSubscriptions.forEach(sub => uniqueSubscriptionTypes.add(sub.fragrance_type));
  
    return (
      <React.Fragment>
        <div className='App'>
        {!this.state.menuOpen && <div id="subscriptionsScreen" className="subscriptionsScreen">
          <img src={othersImg} className="othersImgSmall" alt="otherspng" />
          <button id="burgerMenuButton" className="burgerMenu" onClick={this.menuButtonClicked}>
            <img src={burgerMenu} className="burgerMenuImg" alt="burgerPng" />
          </button>
          {this.state.subscriptions.length > 0 && 
            <div>
                <div className="videoContainer">
                  <video className='activeSubVideo' autoPlay muted loop playsInline preload="auto">
                    <source src={subVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              {/* <div className="titleText smallTitle">Download The App</div> */}
              <div className="mainText subInfo">
                <br></br><br></br><br></br>
                <div className='rowContainer'>
                  <div>(</div>
                <a href="./download">DOWNLOAD THE APP HERE</a>
                  <div>)</div>
                </div>
                <br></br><br></br><br></br>
               {/*<br/><br/> <a href="https://apps.apple.com/us/app/%C3%B8thers/id1615837655">Download for iOS</a>
                <br/><br/><a href="https://play.google.com/store/apps/details?id=co.others.app">Download for Android</a>*/}
                <div className='maintText subInfo'>
                  The ØTHERS app is our digital hub for resilience, mindfulness and wellbeing.
                  <br></br><br></br>
                  Discover daily rituals for any situation - meditation, breathwork, soundscapes, profound ideas, and AI tools for better sleep.
                  <br></br><br></br>
                  Use it alongside your fragrance to create unique dual-sensory rituals, combining scent and sound to improve calm, focus, and emotional balance.
                </div>
              </div>
            </div>
          }
          
          {activeSubscriptions.length > 0 && (
          <div className="activeSubImages">
            {Array.from(uniqueSubscriptionTypes).map((subscriptionType, i) => (
              <img
                key={i}
                className="activeSubImage"
                src={subscriptionType === "red" ? redSkiesBottle : mysticalZyngaroBottle}
                alt={subscriptionType === "red" ? "Red Skies" : "Mystical Zingaro"}
              />
            ))}
          </div>
          )}

          {activeSubscriptions.length > 0 &&
            <div>
              <div className="secondaryText subscription">Your Product Activations</div>
              {/*<div className="subInfo">You have {this.state.subscriptions.length} subscriptions.</div>*/}
            </div>
          }

          {/* {this.state.subscriptions.length > 0 && this.state.subscriptions.map((sub, i) => <p className="mainText" key={i}>{sub.fragrance_type == "red" ? "Red Skies" : "Mystical Zingaro"} expires on {sub.pretty_date}</p>)} */}
          {activeSubscriptions.length > 0 && activeSubscriptions.map((sub, i) => (
                <p className="mainText" key={i}>
                  {sub.fragrance_type === "red" ? "Red Skies" : "Mystical Zingaro"} expires on {sub.pretty_date}
                </p>
              ))}
          {/*this.state.subscriptions.length <= 0 && <div className="subInfo">You have not activated any products</div>*/}

          {expiredSubscriptions.length > 0 && (
            <div className='expiredSection'>
              <div className="secondaryText subscription">Expired Products</div>
              {expiredSubscriptions.length > 0 && expiredSubscriptions.map((sub, i) => (
                <p className="mainText" key={i}>
                  {sub.fragrance_type === "red" ? "Red Skies" : "Mystical Zingaro"} expired on {sub.pretty_date}
                </p>
              ))}
            </div>
          )}

          <div className="activateHere">
            <div className="mainText"> Activate {this.state.subscriptions.length > 0 ? "another" : "your"} ØTHERS product&nbsp;
              <Link to="./redeem">
                <button className="redeemCode" id='test'>here</button>
              </Link>
            </div>
            {/* <div class="mainText"> Buy digital subscription&nbsp;<a href="/redeem"><button class="redeemCode" id="test">here</button></a> </div> */}
          </div>
        </div>}
        {this.state.menuOpen && <div id="menuScreen" className="menuScreen">
          <img src={othersImg} className="othersImgSmall" alt="otherspng"  />
          <button id="xButton" className="xButton"  onClick={this.menuButtonClicked}>
            <img src={xButton} className="xButtonImg" alt="xPng" />
          </button>
          <div className="menuButtonsContainer">
            <div className="menuLines"></div>
            <button className="menuButton menuText" onClick={this.onTheScienceClicked}>THE SCIENCE</button>
            <div className="menuLines"></div>
            <button className="menuButton menuText" onClick={this.onFAQClicked}>FAQ</button>
            <div className="menuLines"></div>
            <button className="menuButton menuText" onClick={this.onTNCClicked}>TERMS AND CONDITIONS</button>
            <div className="menuLines"></div>
            <button className="menuButton menuText" onClick={this.onPrivacyPolicyClicked}>PRIVACY POLICY</button>
            <div className="menuLines"></div>
            <button className="menuButton menuText" onClick={this.onSupportClicked}>CONTACT AND SUPPORT</button>
            <div className="menuLines"></div>
            <button className="menuButton menuText" id='logoutButton' onClick={this.logOutClicked}>LOG OUT</button>
            <div className="menuLines"></div>
            <div className="secondaryText emailInfo">Logged in as {this.state.username}</div>
          </div>
        </div>}
        </div>
      </React.Fragment>
    );
  }
}

export default SubscriptionsPage;